import React, { memo } from 'react';
import { Helmet } from 'react-helmet';

const HeaderMeta: React.FC = () => (
  <Helmet>
    <title>SoundTools</title>
    <meta name='description' content='QUALITY TOOLS FOR THE AUDIO PRO' />
    <link rel='canonical' href={window.location.href} />
  </Helmet>
);

export default memo(HeaderMeta);
