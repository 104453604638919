import { PostOrPage, Tag } from '@tryghost/content-api';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _unescape from 'lodash/unescape';
import _capitalize from 'lodash/capitalize';
import _find from 'lodash/find';
import { isGuideArticle } from '../api-helpers/data-transformer';

export const testRegex: RegExp = /[_].+[a-zA-Z]=(.+[a-zA-Z])/g;

export const getDisplayTagName = (article?: PostOrPage): string => {
  let tagCandidateName: string = _get(article, 'primary_tag.name', '');

  // Use other tags if primary are content
  const otherTags: Tag[] = _get(article, 'tags', []);

  if (otherTags.length > 0 && (tagCandidateName.startsWith('_content=') || _isEmpty(tagCandidateName))) {
    const normalTags: Tag[] = _filter(otherTags, (tag: Tag) => tag.name?.startsWith('_content=') === false && tag.name?.startsWith('#') === false);
    
    if (normalTags.length > 0) {
      tagCandidateName = _get(normalTags, '[0].name', '');
    }
  }

  const reg: RegExp = new RegExp(testRegex);
  const match: RegExpExecArray | null = reg.exec(tagCandidateName);

  return _get(match, 1, tagCandidateName);
};

export const getTag = (article?: PostOrPage): Tag => {
  if (article?.primary_tag) {
    return article.primary_tag;
  }

  const otherTags: Tag[] = _filter(_get(article, 'tags', []), tag => tag.name?.startsWith('#') === false);

  return _get(otherTags, 0);
};

export const descriptionNoLink = (des: string = ''): string => des.replace(/\[http.*?(\]|$)/g, '');

export const getArticleLink = (article: PostOrPage): string => {
  const isGuide: boolean = isGuideArticle(article);

  return `/${isGuide ? 'guides' : 'articles'}/${article?.slug}`;
};

export const youtubeTitle = (title: string = ''): string => _capitalize(_unescape(title)).replace(/&;/g, '\'');

export const generateSourceSet = (imageUrl?: string): string => {
  if (_isEmpty(imageUrl) === true) {
    return '';
  }

  const sizes: number[] = [386, 808, 1230, 2000];
  const images: string[] = _map(sizes, (size: number) => `${(imageUrl as string).replace('/content/images/', `/content/images/size/w${size}/`)} ${size}w`);

  return images.join(', ');
};

export const getPrimaryTag = (post: PostOrPage): Tag | undefined => (
  post?.primary_tag || _find((post?.tags || []), (tag: Tag) => (!tag.slug?.startsWith('hash-')))
);
