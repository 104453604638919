import React, { memo, useMemo } from 'react';
import { useAppContext } from '../context/app-context';
import { filterItems } from '../helpers/contentful-helper';
import LightCarousel from '../components/light-carousel';

const HeaderTopBarPromotion: React.FC = () => {
  const { rawContentFul } = useAppContext();
  const promotions = useMemo(() => {
    return filterItems(rawContentFul, 'topbarPromotion');
  }, [rawContentFul]);

  return (
    <div className='absolute bg-black w-full h-8 leading-8 px-16px text-white lg:hidden z-10'>
      <LightCarousel>
        {
          promotions.map(promotion => (
            <div key={promotion.sys.id} className='flex items-center justify-between text-white px-8 text-[16px]'>
              <div>{promotion.fields.text}</div>
              <div className='cursor-pointer underline'><a href={promotion.fields.ctaLink as string || ''}>{promotion.fields.ctaText}</a></div>
            </div>
          ))
        }
      </LightCarousel>
    </div>
  )
};

export default memo(HeaderTopBarPromotion);
