import { PostOrPage } from '@tryghost/content-api';
import { createContext, useContext } from 'react';
import { IContentFulRaw } from '../model/contentful-model';

export interface IAppContextStore {
  hasSubscribed: boolean;
  onSubscribe: (email: string, fromForm: string) => void;
  allPosts: PostOrPage[];
  subscribeText: string;
  rawContentFul?: IContentFulRaw;
  featuredHumans: PostOrPage[];
}

export const AppContext = createContext<IAppContextStore>({
  hasSubscribed: false,
  onSubscribe: (_email: string, _fromForm: string) => {},
  allPosts: [],
  subscribeText: '',
  rawContentFul: {} as IContentFulRaw,
  featuredHumans: []
});

export const useAppContext = () => useContext(AppContext);
