import React, { memo, useEffect, useMemo, useState } from 'react';
import _sortBy from 'lodash/sortBy';
import Breadcrumb from '../components/breadcrumb';
import { useAppContext } from '../context/app-context';
import { filterItems } from '../helpers/contentful-helper';
import CompactProductCard from '../components/compact-product-card';
import ProductCategories from '../components/product-categories';
import './products-page.css';
import { useSearchParams } from 'react-router-dom';

const ProductsPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { rawContentFul } = useAppContext();
  const productsFiltered = useMemo(() => {
    return _sortBy(filterItems(rawContentFul, 'product'), 'fields.name');
  }, [rawContentFul]);
  const [products, setProducts] = useState(productsFiltered);
  const queryParam = useMemo(() => searchParams.get('category'), [searchParams]);


  useEffect(() => {
    //@ts-ignore
    setProducts(productsFiltered.filter((product => {
      const categoryParam = queryParam;
      
      if (categoryParam === 'all products' || !categoryParam) return true;
      //@ts-ignore
      return product?.fields?.category?.fields?.name?.toString()?.toLowerCase() === categoryParam;
    })));
  }, [productsFiltered, queryParam]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  return (
    <div className='product-container w-full h-full bg-[#F9F9F9]'>
      <div className='flex flex-col'>
        <div className='text-left bg-white'>
          <div className='px-4 lg:px-76px box-content py-6 w-full'>
            <Breadcrumb data={[
              {
                text: 'Product Page',
                link: '/products'
              }
            ]} />
          </div>

        </div>
        <ProductCategories hasCategoryDescription={true} hasAllProductInList={true} />
      </div>
      <div className='px-4 pt-4 pb-16 lg:px-16 xl:max-w-container xl:px-0 mx-auto w-full border-b'>
        <div className='products'>
          <div className='flex flex-col md:grid md:grid-cols-2 lg:grid-cols-3 gap-5 mt-7 md:mt-9'>
            {products.map(product => (
              //@ts-ignore
              <div className='flex product-item bg-white p-4' key={product?.sys?.id}>
              <CompactProductCard entry={product} isShowPrice={false} isShowDetails={true} />
            </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    
  )
};

export default memo(ProductsPage);
