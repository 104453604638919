import React, { memo } from 'react';
import { kebabCase } from 'lodash';
import { IContentFulEntry } from '../model/contentful-model';
import { Link } from 'react-router-dom';

interface IProps {
  entry: IContentFulEntry
  smallCard?: boolean
  isShowPrice?: boolean
  isShowDetails?: boolean
}

const CompactProductCard: React.FC<IProps> = ({ entry, smallCard, isShowPrice, isShowDetails }) => {
  const onImageClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
  return (
    <div className='bg-[#F9F9F9] py-8 h-full compact-product-card-wrapper w-full'>
      {/* @ts-ignore */}
      <Link to={`/products/${kebabCase(JSON.stringify(entry?.fields?.name))}`} className={`w-full ${smallCard ? 'px-2' : 'px-8'}`}><img onClick={onImageClick} className='w-full hover:scale-105 cursor-pointer transition-all' src={entry?.fields?.thumbnailImage?.fields?.file?.url} alt={entry?.fields?.name} /></Link>
      <div className='text-center content-wrapper'>
        <div>
        <div className={`font-inter text-[18px] px-16px cursor-pointer hover:scale-105`} onClick={onImageClick}>
          <Link to={`/products/${kebabCase(JSON.stringify(entry?.fields?.name))}`} className={`w-full ${smallCard ? 'px-2' : 'px-8'}`}>{entry?.fields?.name}</Link>
        </div>

        {isShowPrice && <div className='flex justify-center items-center mt-4'>
          <div className='text-2xl font-inter font-medium'>
            {entry?.fields?.price ? `$${entry?.fields?.price}.`  : 'Price varies by length'}
          </div>
          {Boolean(entry?.fields?.price) && <span className='pb-2 font-inter font-medium'>00</span>}
        </div>}

        {
          !smallCard && (
            <p className='w-full text-wrap mt-4 text-[16px] text-center px-8'>
              {entry?.fields?.description?.toString()}
            </p>
          )
        }
        </div>
        <div className='flex flex-col items-center xxxs:flex-row justify-center gap-5 mt-5' onClick={onImageClick}>
          <Link to={`/products/${kebabCase(JSON.stringify(entry?.fields?.name))}`}>
              {(isShowPrice || isShowDetails) && <div className='border-black hover:bg-black/[0.2] hover:cursor-pointer transition-all flex items-center justify-center space-x-5 border rounded-[20px] text-black h-[40px] leading-[40px] px-5 w-fit'>
              <div>
              <div className='font-inter cursor-pointer font-bold text-[14px] w-full'>Details</div>
              </div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" height={15}><path fill='' d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
            </div>}
          </Link>
          {/* @ts-ignore */}
          <a target='_blank' href={entry?.fields?.buyUrl || 'https://www.amazon.com/stores/Sound+Tools/page/CA903747-A545-4448-8D59-488335F86A41?ref_=ast_bln'} rel="noreferrer">
            <div className='bg-black hover:bg-black/[0.7] transition-all text-white h-[40px] rounded-[20px] space-x-3 leading-[40px] w-[130px] flex justify-center items-center'>
              <div>
                <div className='w-full'>Buy Now</div>
              </div>
              <svg className='-rotate-45' xmlns="http://www.w3.org/2000/svg" height={20} viewBox="0 0 448 512"><path fill='white' d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>
            </div>
          </a>
        </div>
        
      </div>
    </div>
  )
};

export default memo(CompactProductCard);
