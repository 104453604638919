import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import Breadcrumb from '../components/breadcrumb';
import SupportEmailLogo from '../assets/support-email-logo.svg';
import SupportGearLogo from '../assets/support-gear.svg';
import SupportWarrantyLogo from '../assets/support-warranty-logo.svg';
import "react-international-phone/style.css";
import './support-page.css';
import { useAppContext } from '../context/app-context';
import { filterItems } from '../helpers/contentful-helper';

interface ISupportCardProps {
    supportCardLogo: string;
    header?: string;
    description?: string;
    buttonText?: string;
    link?: string;
    handleFocus?: () => void;
}

const SupportCard: React.FC<ISupportCardProps> = ({ supportCardLogo, header, description, link, handleFocus }) => {
    return (
        <div className='bg-white pb-16 h-full w-full flex flex-row md:flex-col justify-center'>
            <div className='md:mx-auto aspect-square w-[126px] mt-16 m-6'>
                <img src={supportCardLogo} className='hover:scale-105 w-full h-full object-contain transition-all' alt='logo' />
            </div>
            <div className='text-center content-wrapper w-full flex flex-col items-start md:items-center'>
                <div className='font-inter text-[18px] mt-9'>{header}</div>
                <div className='text-wrap mt-2 font-sans text-[16px] text-center'>{description}</div>
                <div className='bg-black hover:bg-black/[0.7] transition-all text-white h-[40px] rounded-[20px] mt-6 space-x-3 leading-[40px] w-[130px] flex justify-center items-center'>
                    <div>
                        { !handleFocus ? <a target='_blank' href={link || '/'} rel="noreferrer">Read more</a> : <button onClick={handleFocus}>Read more</button> }
                    </div>
                </div>
            </div>
        </div>
    );
};

const SupportPage: React.FC = () => {
  const { rawContentFul } = useAppContext();
  const supportFormRef = useRef<HTMLDivElement>(null);
  const supportPage = useMemo(() => filterItems(rawContentFul, 'supportPage')?.[0], [rawContentFul]);
  const handleFocus = useCallback(() => {
      const element = document.querySelector('.contact-more-form');

      if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
      }

  }, []);

  useEffect(() => {
    const src = 'https://form.jotform.com/jsform/82757927757982';
    const isLoaded: Element | null = document.querySelector('script[src="' + src + '"]');

    if (isLoaded) {
      /* eslint-disable no-unused-expressions */
      isLoaded.parentNode?.removeChild(isLoaded);
    }

    const supportForm: HTMLScriptElement = document.createElement('script');
    supportForm.type = 'text/javascript';
    supportForm.src = src;

    supportFormRef.current?.appendChild(supportForm);
  }, [])

  return (
    <div className='product-container w-full h-full bg-[#F9F9F9]'>
      <div className='flex flex-col'>
        <div className='text-left bg-white'>
          <div className='px-4 lg:px-76px box-content py-6 w-full bg-[#F9F9F9]'>
            <Breadcrumb data={[
              {
                text: 'About SoundTools',
                link: '/support'
              }
            ]} />
          </div>
        </div>
      </div>
      <div className='px-4 pt-4 pb-20 lg:px-16 xl:max-w-container xl:px-0 mx-auto w-full'>
              <div className='flex flex-col md:flex-row product-item gap-7'>
                <SupportCard
                  //@ts-ignore
                  supportCardLogo={ supportPage?.fields?.firstCardImage?.fields?.file?.url || SupportGearLogo}
                  header={(supportPage?.fields?.firstCardTitle as string) || 'Product Support'}
                  description={ (supportPage?.fields?.firstCardDescription as string) || 'Need technical support?' }
                  link={ (supportPage?.fields?.firstCardUrl as string) || 'https://support.soundtools.com/' } />
                <SupportCard
                  //@ts-ignore
                  supportCardLogo={ supportPage?.fields?.secondCardImage?.fields?.file?.url || SupportWarrantyLogo}
                  header={ (supportPage?.fields?.secondCardTitle as string) || 'Warranty'}
                  description={ (supportPage?.fields?.secondCardDescription as string) || 'Start the warranty process'}
                  link={ (supportPage?.fields?.secondCardUrl as string)  || 'https://form.jotform.com/212776583895070'} />
                <SupportCard
                  //@ts-ignore
                  supportCardLogo={ supportPage?.fields?.thirdCardImage?.fields?.file?.url || SupportEmailLogo}
                  header={ (supportPage?.fields?.thirdCardTitle as string) || 'Email us'}
                  description={ (supportPage?.fields?.thirdCardDescription as string) || 'Contact us if your question isn’t answered'}
                  handleFocus={handleFocus} />
            </div>
      </div>
      <div className='w-full bg-white'>
      <div className='flex flex-col xl:flex-row gap-14 xl:max-w-container mx-4 md:mx-auto bg-white py-20'>
        <div className='google-map lg:w-1/2 max-w-3xl mx-auto sm:p-6 lg:mt-12'>
            <div dangerouslySetInnerHTML={{ __html: (supportPage?.fields?.mapEmbbed as string) || `
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3299.5858923386263!2d-119.03368602434786!3d34.208055573098676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80e8363bac46bee5%3A0x5fbea9c86b5bc34f!2sRat%20Sound%20Systems%2C%20Inc!5e0!3m2!1sen!2s!4v1712795757611!5m2!1sen!2s" width="600" height="450" style={{ border: 0 }} title='soundtool-address' allowFullScreen={false} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            `}} />
        </div>
        <div ref={supportFormRef} className='contact-more-form w-11/12 mx-auto sm:p-6 lg:p-0'>
        </div>

      </div>
      </div>
    </div>
  )
};

export default memo(SupportPage);
