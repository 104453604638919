import React, {memo, MouseEventHandler, useEffect, useMemo} from 'react';
import _map from 'lodash/map';
import _range from 'lodash/range';
import {Link, Path, To, useLocation} from "react-router-dom";

interface IProps {
  maxDisplayItem?: number;
  currentPage: number;
  totalItems: number;
  itemPerPage: number;
  onChangePage: (page: number) => void;
}

const PageFrame: number = 3;

interface IPageParts {
  firstPart: number[];
  secondPart: number[];
  lastPart: number[];
}

interface ILinkOrButton {
  disabled: boolean;
  to: To;
  className: string;
  onClick: MouseEventHandler<HTMLAnchorElement|HTMLButtonElement>;
}

const bindToFunction = ({search, ...rest}: Path) => (page: number) => {
  const searchParams = new URLSearchParams(search);
  searchParams.set('page', page.toString());

  return {
    ...rest,
    search: searchParams.toString()
  };
};

const LinkOrDisabledButton :React.FC<ILinkOrButton> = ({disabled, to, onClick, ...rest}) => disabled
    ?  (<button disabled={true} {...rest} />)
    :  (<Link to={to} onClick={onClick} {...rest}/>);

const bindCreateOnChangePage = (onChangePage: (page: number) => void) => (page: number) => (event: React.MouseEvent<HTMLAnchorElement>) => {
  event.preventDefault();
  event.stopPropagation();
  onChangePage(page);
};

const Pagination: React.FC<IProps> = ({ onChangePage, currentPage, totalItems, itemPerPage }) => {
  const totalPages: number = useMemo(() => Math.ceil(totalItems / itemPerPage), [totalItems, itemPerPage]);
  const isFirstPage: boolean = useMemo(() => currentPage === 1, [currentPage]);
  const isLastPage: boolean = useMemo(() => currentPage === totalPages, [currentPage, totalPages]);
  const pages: number[] = useMemo(() => _range(1, totalPages + 1), [totalPages]);
  const noPageTruncate: boolean = useMemo(() => PageFrame >= totalPages, [totalPages]);
  const parts: IPageParts = useMemo(() => {
    if (noPageTruncate === true) {
      return {
        firstPart: pages,
        secondPart: [],
        lastPart: []
      }
    }

    let firstPart: number[] = [];
    let secondPart: number[] = [];
    let lastPart: number[] = [];
  
    if (currentPage < PageFrame) {
      firstPart = pages?.slice(0, PageFrame);
      secondPart = [];
      lastPart = pages?.slice(-(PageFrame - (PageFrame - 1)));

      return {
        firstPart,
        secondPart,
        lastPart
      }
    }

    if (currentPage <= totalPages - PageFrame) {
      firstPart = pages?.slice(0, PageFrame - (PageFrame - 1));
      secondPart = [currentPage, currentPage + 1];
      lastPart = [totalPages];

      return {
        firstPart,
        secondPart,
        lastPart
      }
    }

    return {
      firstPart: [1],
      secondPart: [],
      lastPart: pages?.slice(-PageFrame)
    }
  }, [currentPage, pages, noPageTruncate, totalPages]);


  // Need to support Link and button
  const location = useLocation();
  const createToLocation = bindToFunction(location);
  const createOnChangePage = bindCreateOnChangePage(onChangePage);

  useEffect(() => {
    onChangePage(currentPage > totalPages ? totalPages: currentPage);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPages, currentPage]);

  return (
    <div className='flex'>
      <div className='mx-auto flex items-center justify-center space-x-3 sm:space-x-6 space-y-3 flex-nowrap'>
        <LinkOrDisabledButton
            disabled={isFirstPage}
            to={createToLocation(currentPage - 1)}
            className='mt-3 text-center disabled:hidden'
            onClick={createOnChangePage(currentPage - 1)}
        >
          <span className='bg-black ml-3 text-white h-[40px] rounded-[20px] space-x-3 leading-[40px] w-fit px-8 flex justify-center items-center'>Back</span>
        </LinkOrDisabledButton>
        {
          _map(parts.firstPart, (page: number) => (
            <Link
              to={createToLocation(page)}
              onClick={createOnChangePage(page)}
              key={page}
              className={`leading-8 text-center mt-3 hover:underline ${page === currentPage ? 'font-bold' : ''}`}
            >{page}</Link>
          ))
        }
        {
          noPageTruncate === false && parts.firstPart.length < PageFrame && (<div>...</div>)
        }
        {
          _map(parts.secondPart, (page: number) => (
            <Link
              to={createToLocation(page)}
              onClick={createOnChangePage(page)}
              key={page}
              className={`leading-8 text-center ${page === currentPage ? 'font-bold' : ''}`}
            >{page}</Link>
          ))
        }
        {
          noPageTruncate === false && parts.lastPart.length < PageFrame && (<div>...</div>)
        }
        {
          _map(parts.lastPart, (page: number) => (
            <Link
              to={createToLocation(page)}
              onClick={createOnChangePage(page)}
              key={page}
              className={`leading-8 text-center ${page === currentPage ? 'font-bold' : ''}`}
            >{page}</Link>
          ))
        }
        <LinkOrDisabledButton
            disabled={isLastPage}
            to={createToLocation(currentPage + 1)}
            className='disabled:hidden'
            onClick={createOnChangePage(currentPage + 1)}>
          <span className='bg-black ml-3 text-white h-[40px] rounded-[20px] space-x-3 leading-[40px] w-fit px-8 flex justify-center items-center'>Next</span>
        </LinkOrDisabledButton>
      </div>
    </div>
  )
};

export default memo(Pagination);
