import { memo, useMemo, useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import PlusIcon from '../../../assets/plus-icon.svg';
import MinusIcon from '../../../assets/minus-icon.svg';
import { separateRichTextNode } from '../../../helpers/common';
import { useInfoTabContext } from '../../../context/infotab-context';

const FAQItem: React.FC<{ question: string, answer: any }> = memo(({ question, answer }) => {
    const [isActive, setIsActive] = useState(false);

    const toggleQuestion = () => {
        setIsActive(!isActive);
    };
    const option = useMemo(() => {
        return {
            renderNode: {
                [BLOCKS.UL_LIST]: (node: any, children: any) => <ul className='mt-[14px]'>{children}</ul>,
                [BLOCKS.LIST_ITEM]: (node: any, children: any) => <li className='text-16 font-inter'>{children}</li>,
                [BLOCKS.HEADING_3]: (node: any, children: any) => <h3 className='font-inter text-[20px]'>{children}</h3>,
                [INLINES.ASSET_HYPERLINK]: (node: any, children: any) => {
                    return <a className='hover:text-red-800' target='_blank' rel='noreferrer' href={`${node?.data?.target?.fields?.file?.url}`}>{node?.data?.target?.fields?.description ? <img className='mt-4' alt='pdf' src={node?.data?.target?.fields?.description} /> : children}</a>
                },
                [INLINES.HYPERLINK]: (node: any, children: any) => <a target='_blank' rel='noreferrer' className='hover:text-red-800' href={`${node?.data?.uri}`}>{children}</a>,
            }
        }
    }, []);

    return (
        <li onClick={toggleQuestion} className={`w-full bg-[#F9F9F9] min-h-20 cursor-pointer  px-6 pt-7 flex flex-col pb-6 question-box ${isActive ? 'active' : ''}`}>
            <div className={'flex flex-row justify-between gap-4'}>
                <div>{question}</div>
                <div className='block my-auto w-18px min-w-[18px] min-h-[18px] md:min-w-[21px] md:min-h-[21px]'><img src={ isActive ? MinusIcon : PlusIcon } alt='warranty' /></div>
            </div>
            <div className='answer-box w-full'>
                {answer && documentToReactComponents(answer, option)}
            </div>
            
        </li>
    );
});


const FAQ: React.FC = () => {
    const { product } = useInfoTabContext();
    
    const faq: any = useMemo(() => {
        const seperatedFaq =  separateRichTextNode(product?.[0]?.fields?.faq?.content || []);
        return seperatedFaq.map(element => ({
            nodeType: BLOCKS.DOCUMENT,
            content: element,
            data: {}
        }));
      }, [product]);

    return (
        <div className='relative'>
            <ul className='faq-tab m-auto flex flex-col gap-5 mb-10 md:mb-0 md:mt-14 w-full md:w-4/5 mx-auto'>
                {faq?.map((el: any, index: number) => <FAQItem key={`faq_${index}`} question={el?.content?.[0]?.content?.[0]?.value} answer={{ ...el, content: [...el?.content?.slice(1)]}} />)}
            </ul>
        </div>
    );
};

export default memo(FAQ);