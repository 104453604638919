import React, { ChangeEvent, memo, useMemo, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { IAppContextStore, useAppContext } from '../context/app-context';
import FooterIcon from './footer-icon';
import CheckIcon from '../assets/check-mark.svg';
import { filterItems } from '../helpers/contentful-helper';
import { separateRichTextNode } from '../helpers/common';

const Footer: React.FC = () => {
  const [text, setText] = useState('');
  const { onSubscribe, hasSubscribed, subscribeText, rawContentFul }: IAppContextStore = useAppContext();
  const footerInfo = useMemo(() => filterItems(rawContentFul, 'footerSection')?.[0], [rawContentFul]);
  const option = useMemo(() => {
    return {
        renderNode: {
            [BLOCKS.DOCUMENT]: (node: any, children: any) => <div className='flex flex-col flex-1 text-left space-y-2'>{children}</div>,
            [BLOCKS.UL_LIST]: (node: any, children: any) => <ul className='mt-[14px]'>{children}</ul>,
            [BLOCKS.LIST_ITEM]: (node: any, children: any) => <li className='text-16 font-inter'>{children}</li>,
            [BLOCKS.HEADING_3]: (node: any, children: any) => <h3 className='font-bold'>{children}</h3>,
            [INLINES.ASSET_HYPERLINK]: (node: any, children: any) => <a className='hover:text-red-800' target='_blank' rel='noreferrer' href={`${node?.data?.target?.fields?.file?.url}`}>{children}</a>,
            [INLINES.HYPERLINK]: (node: any, children: any) => <a target='_blank' rel='noreferrer' className='break-words hover:underline' href={`${node?.data?.uri}`}>{children}</a>,
        }
    }
  }, []);

  const footerNav: any = useMemo(() => {
  // @ts-ignore
    const seperatedSpecDetail =  separateRichTextNode(footerInfo?.fields?.footerNavigation?.content || []);
    return seperatedSpecDetail.map(element => ({
        nodeType: BLOCKS.DOCUMENT,
        content: element,
        data: {}
    }));
  }, [footerInfo]);

  const subscribeBlub = useMemo(() => footerInfo?.fields?.subscribeBlub, [footerInfo]);
  //@ts-ignore
  const socialLink: any = useMemo(() => footerInfo?.fields?.socialLink, [footerInfo]);

  return (
    <div className='bg-[#F9F9F9] px-16px text-center lg:px-0'>
      <div className='py-30px border-b border-[#D9D9D9] text-16 lg:flex'>
        <div className='grid grid-cols-2 lg:grid-cols-4 gap-10 gap-y-14 mb-8 lg:mb-5'>
          {
            footerNav?.map((element: any, index: number) => {
                return (
                <div key={`specs_${index}`}>
                    {element && (documentToReactComponents(element, option))}
                </div>
                );
            })
          }
        </div>
        <div className='flex flex-col ml-0 lg:ml-20 w-full lg:max-w-sm'>
        <div className='lg:text-left'>
          <div className='text-left font-inter text-[18px] font-bold'>{footerInfo?.fields?.subscribeTitle || 'Subscribe'}</div>
          <div className='text-left my-4'>{subscribeBlub || 'Join our subscribers list and get access to the latest tools, freebies, product announcements and much more!'}
          </div>
          <div className='lg:w-3/4 sm:max-w-subscribe-width lg:mx-auto flex my-4 lg:max-w-subscribe-width lg:ml-0 relative'>
            {
              hasSubscribed === true ? (
                <div className='mx-auto lg:ml-0 border border-solid p-4 rounded-lg flex flex-row gap-3 justify-center items-center'>
                  {footerInfo?.fields?.signUpSuccessMessage || subscribeText}
                  <img className='block rounded-full w-8 h-8 bg-black p-2' src={CheckIcon} alt='share-tool' />
                </div>
              ) : (
                <>
                  <label className='hidden' htmlFor='input-email-footer'>Your email here</label>
                  <input
                    id='input-email-footer'
                    className='py-2.5 px-4 flex-grow rounded-[20px] pr-14'
                    type='email'
                    //@ts-ignore
                    placeholder={footerInfo?.fields?.emailPlaceholder || 'your@email.com'}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setText(event.target.value)}
                  />
                  <button
                    disabled={_isEmpty(text) === true}
                    onClick={() => onSubscribe(text, 'footer')}
                    className='bg-black transition-all cursor-pointer rounded-full w-[40px] h-[40px] absolute right-0.5 top-0.5 block hover:bg-green-900'>
                      <svg xmlns="http://www.w3.org/2000/svg" className='mx-auto' height={20} viewBox="0 0 512 512"><path fill='white' d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z"/></svg>
                    </button>
                </>
              )
            }
          </div>
        </div>
        <div className='lg:flex lg:mr-0 lg:text-right'>
          <div className='flex justify-center space-x-3 mt-6 lg:justify-end lg:mt-4'>
            <FooterIcon links={[
              socialLink?.facebook || 'https://www.facebook.com/',
              socialLink?.x || 'https://x.com/ratsoundtools',
              socialLink?.instagram || 'https://www.instagram.com/ratsoundtools/?hl=en',
              socialLink?.youtube || 'https://www.youtube.com/c/soundtoolschannel'
            ]} />
          </div>
        </div>
        </div>
      </div>
      <div className='py-16px text-14 text-[#7B7B7B] flex flex-col sm:flex-row sm:justify-between lg:justify-center'>
        <div className='capitalize mt-2 sm:mt-0'>{ footerInfo?.fields?.copyright || `Copyright © ${new Date().getFullYear()} SoundTools. All Rights Reserved.`}</div>
      </div>
    </div>
  )
};

export default memo(Footer);
