import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const MountEffectSetReactRenderedOnRoot = () => {
    useEffect(() => {
        const rootElement = document.getElementById('root');
        if(!rootElement){
            return;
        }
        
        rootElement.dataset.reactRendered = 'true';
    });
    return null;
};

ReactDOM.render(
  <React.StrictMode>
    <MountEffectSetReactRenderedOnRoot />
    <BrowserRouter basename={process.env.REACT_APP_BASE_FOLDER || '/'}>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
