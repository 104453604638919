import { PostOrPage, PostsOrPages, Tag } from '@tryghost/content-api';
import _omit from 'lodash/omit';
import _map from 'lodash/map';
import _some from 'lodash/some';
import _get from 'lodash/get';

export const ArticleTag: string = 'hash-category-article';
export const GuideTag: string = 'hash-category-guide';

export const getPosts = (response: PostsOrPages): PostOrPage[] => _map(_omit(response, 'meta') as PostOrPage[]);

export const isGuideTag = (tag: Tag): boolean => tag?.slug.toLowerCase() === GuideTag;

export const isGuideArticle = (article?: PostOrPage): boolean => typeof article === 'undefined' ? false : _some([_get(article, 'primary_tag')].concat(_get(article, 'tags', [])), isGuideTag);
