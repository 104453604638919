import React, { memo, useMemo } from 'react';
import _sortBy from 'lodash/sortBy';
import { useAppContext } from '../../context/app-context';
import { filterItems } from '../../helpers/contentful-helper';
import { Link } from 'react-router-dom';

const HomePageDesktopProductCategory: React.FC = () => {
  const { rawContentFul } = useAppContext();
  const categories = useMemo(() => {
    return _sortBy(filterItems(rawContentFul, 'productCategory'), 'fields.order');
  }, [rawContentFul]);

  return (
    <div className='lg:flex items-center mt-7 hidden'>
      {
        categories?.slice(1).map(item => (
          <div className='flex-1 aspect-square flex flex-col-reverse relative' key={item.sys.id}>
            
            <div className='absolute right-0 bottom-0 w-full h-full bg-cover' style={{ backgroundImage: `url(${
              // @ts-ignore
              item.fields?.desktopPhoto?.fields?.file?.url
              })` }}>
              
            </div>
            <div className='flex flex-col p-5 z-10'>
            <div className='xl:text-[22px] md:text-[18px] h-20 text-white uppercase font-bold min-h-[66px] max-w-[170px]'>
              {item.fields.name}
            </div>
            <Link to={`/products?category=${encodeURIComponent(item?.fields?.name?.toString().toLowerCase() || '')}`}>
              <div className='border-white flex items-center justify-center space-x-3 border rounded-[20px] text-white h-[30px] leading-[30px] mt-4 px-2 w-[140px] hover:bg-white/[0.3] transition-all'>
                <div>
                  Learn More
                </div>
                <svg className='hover:fill-black' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" height={15}><path fill='white' d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
              </div>
            </Link>
            </div>
          </div>
        ))
      }
    </div>
  )
};

export default memo(HomePageDesktopProductCategory);
