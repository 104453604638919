import { GhostAPI } from '@tryghost/content-api';
import React, { memo, useEffect, useMemo, useState } from 'react';
import Breadcrumb from '../components/breadcrumb';
import SectionFeed from '../layout/section-feed';
import { IAppContextStore, useAppContext } from '../context/app-context';
import withContentApi from '../hoc/withContentApi';
import { useSearchParams } from 'react-router-dom';

interface IProps {
  api?: GhostAPI;
};

const SectionFrontPage: React.FC<IProps> = () => {
  const { allPosts, featuredHumans }: IAppContextStore = useAppContext();
  const [isMobile, setIsMobile] = useState(false);
  const [searchParams] = useSearchParams();
  const filterTags = useMemo(() => searchParams.get('filter')?.split(','), [searchParams]);
  const displayArticles = useMemo(() => {
    if (filterTags?.includes('human')) {
      return featuredHumans;
    }

    return allPosts;
  }, [allPosts, featuredHumans, filterTags]);

  useEffect(() => {
    setIsMobile(window?.innerWidth <= 768);
    const handleResize = () => {
        setIsMobile(window?.innerWidth <= 768);
    };
    window?.addEventListener('resize', handleResize);
    return () => window?.removeEventListener('resize', handleResize);
}, []);
  
  return (
    <div className='w-full h-full'>
      <div className='text-left bg-white'>
        <div className='px-4 lg:px-76px box-content py-6 w-full'>
          <Breadcrumb data={[
            {
              text: 'Our News',
              link: '/articles'
            }
          ]} />
        </div>
      </div>

      <div className='flex flex-col p-16px max-w-md-container xl:max-w-container mx-auto'>
        <div className='flex flex-col sm:flex-row pb-3 pt-26px sm:pt-16px'>
          <div className='w-full'>
            <div className='font-inter text-[24px] text-center mb-5'>
              Our News
            </div>
            <SectionFeed
              articlePerpage={isMobile ? 3 : 6}
              tags={[]}
              className='grid grid-cols-1 gap-4 sm:gap-5 lg:grid-cols-3 lg:gap-8'
              allowLoadmore
              posts={displayArticles}
            />
          </div>
        </div>
      </div>
    </div>
    
  )
};

export default memo(withContentApi(SectionFrontPage));
