import React, { isValidElement, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { isNil, kebabCase } from 'lodash';
import { BLOCKS } from '@contentful/rich-text-types';
import Breadcrumb from '../components/breadcrumb';
import { useAppContext } from '../context/app-context';
import { filterItems } from '../helpers/contentful-helper';
import CompactProductCard from '../components/compact-product-card';
import { Link, useParams } from 'react-router-dom';
import WarrantyImage from '../assets/warranty.svg';
import ImageMagnify from '../components/product-details/image-magnify';
import InfoTab from '../components/product-details/info-tab';
import './products-page.css';
import './product-details-page.css';
import { useScrollDirection } from '../hooks/useScrollDirection';

interface IRouteParams {
  productId: string;
  tag: string;
}

const ProductDetailsPage: React.FC = () => {
  const { productId } = useParams<keyof IRouteParams>();
  const { rawContentFul } = useAppContext();
  const scrollDirection = useScrollDirection();
  const sectionInfo = useMemo(() => filterItems(rawContentFul, 'sectionInfo')?.filter(data => (
    data?.fields?.type === 'featuredProductSection'
  ))?.[0], [rawContentFul]);
  const rawProducts = useMemo(() => {
    return filterItems(rawContentFul, 'product');
  }, [rawContentFul])
  const featuredProducts = useMemo(() => {
    return rawProducts?.filter(item => item?.fields?.isFeatured)?.sort(() => 0.5 - Math.random()).slice(0, 3);
  }, [rawProducts]);
  const [product, images] = useMemo(() => {
    const product: any = rawProducts?.filter(item => kebabCase(JSON.stringify(item?.fields?.name)) === productId);
    const images: any = product?.[0]?.fields?.media;
    return [product, images];
  }, [productId, rawProducts]);

  const color: string[] = useMemo(() => {
    const color = new Set();
    product?.[0]?.fields?.mediaFemale?.forEach((el: any) => {
      color.add(el?.fields?.description || '');
    });
    
    product?.[0]?.fields?.media?.forEach((el: any) => {
      color.add(el?.fields?.description || '');
    });

    //@ts-ignore
    return [...color]?.sort()?.filter(el => !isNil(el)) as string[];
  }, [product]);

  const [currentImage, setCurrentImage] = useState(0);
  const [selectedImages, setSelectedImages] = useState(images);
  const [selectedGenderValue, setSelectedGenderValue] = useState('male');
  const [colorValue, setColorValue] = useState(color?.[0] || '');

  const option = useMemo(() => {
    return {
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node: any, children: any) => {
              return <img src={`${node?.data?.target?.fields?.file?.url}`} alt={`${node?.data?.target?.fields?.title}`} className='rounded-2xl' />
            },
            [BLOCKS.HEADING_3]: (node: any, children: any) => <div className='font-inter text-20 mb-5 pl-2 lg:pl-16 capitalize'>{children}</div>,
            [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
              const videoSrc = children?.[0];

              if (isValidElement(videoSrc)) {
                return <div>{children}</div>;
              }

              return children?.[0]?.includes('youtu') ? 
                <div className='youtube-embeded'><iframe className='w-full h-full min-h-[400px] rounded-2xl' src={videoSrc} title='YouTube video player' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' referrerPolicy='strict-origin-when-cross-origin' allowFullScreen></iframe></div>
                : <div>{children}</div>;
            },
            [BLOCKS.UL_LIST]: (node: any, children: any) =>  <ul className='flex flex-col gap-4 list-disc pl-5 py-5 contentful-list'>{children}</ul>,
            [BLOCKS.HR]: (node: any, children: any) => <div className='mt-4'></div>,
            [BLOCKS.LIST_ITEM]: (node: any, children: any) => <li className='px-3'>{children}</li>,
            [BLOCKS.TABLE]: (node: any, children: any) => <div className='border border-solid rounded-2xl my-4'><table className='table-fixed text-center rounded-2xl p-6 border border-solid overflow-hidden w-full font-sans'>{children}</table></div>,
            [BLOCKS.TABLE_HEADER_CELL]: (node: any, children: any) => <th className=' font-semibold text-16 p-2 border bg-gray-300'>{children}</th>,
            [BLOCKS.TABLE_ROW]: (node: any, children: any) => <tr className='border'>{children}</tr>,
            [BLOCKS.TABLE_CELL]: (node: any, children: any) => <td className='border'>{children}</td>,
        },
        preserveWhitespace: true,
    }
}, []);

  const handleRadioChange = useCallback((event: any) => {
    setSelectedGenderValue(event.target.value);
  }, []);

  const handleColorChange = useCallback((event: any) => {
    setColorValue(event.target.value);
  }, []);

  const handleClickImage = useCallback((index) => {
    setCurrentImage(index);
  }, []);

  useEffect(() => {
    setColorValue(color?.[0])
  }, [color]);

  useEffect(() => {
    if (selectedGenderValue === 'female') {
      setSelectedImages(product?.[0]?.fields?.mediaFemale?.filter((image: any) => {
        if (colorValue === '') {
          return image?.fields?.description === colorValue || image?.fields?.description === undefined;
        }
        return image?.fields?.description === colorValue;
      }));
    } else {
      setSelectedImages(product?.[0]?.fields?.media?.filter((image: any) => {
        if (colorValue === '') {
          return image?.fields?.description === colorValue || image?.fields?.description === undefined;
        }
        return image?.fields?.description === colorValue;
      }));
    }
  }, [selectedGenderValue, images, product, colorValue]);

  useEffect(() => {
    if (!selectedImages?.[currentImage]) {
      setCurrentImage(0);
    }

    if (!selectedImages?.length) {
      setSelectedGenderValue((prev: any) => prev === 'male' ? 'female' : 'male');
    }
  }, [currentImage, rawContentFul, selectedImages]);

  return (
  <div>
    <div className='breadcrumb-box px-4 lg:px-76px box-content py-6 w-full' style={{ backgroundColor: product?.[0]?.fields?.category?.fields?.colorHex || 'black' }}>
      <Breadcrumb data={[
        {
          text: `Product Page / ${product?.[0]?.fields?.name}`,
          link: `/products/${productId}`
        }
      ]} />
    </div>
    <div className='px-4 pt-4 mt-12 pb-16 lg:px-16 xl:max-w-container xl:px-0 mx-auto w-full flex flex-col md:flex-row'>
      <div className='product-images flex flex-col w-full md:w-1/2'>
        <div className='hidden md:block md:w-full transition-all'>
          <ImageMagnify magnifierHeight={200} magnifieWidth={200} src={(selectedImages)?.[currentImage]?.fields?.file?.url} />
        </div>
        <div className='inline-block relative'>
          <div className='image-thumbnail flex overflow-x-auto gap-5 custom-scrollbar z-[1] relative'>
            
            { // @ts-ignore
            (selectedImages)?.map((imageRaw, index) => {
              const image = imageRaw?.fields?.file?.url;
              return <img key={`image_${index}`} onClick={() => handleClickImage(index)} className={`w-[269px] h-[269px] md:w-28 md:h-20 object-contain cursor-pointer ${currentImage === index ? 'md:opacity-40': ''} transition-all`} src={image} alt='img'/>
            })}
          </div>
          <div className='w-full border-b border-solid absolute bottom-0.5 xs:hidden'></div>
        </div>
      </div>  
      <div className='product-content-container pr-5 md:w-1/2 md:p-6 md:ml-12'>
        <div className='w-full'>
          <div className='w-full font-inter text-[22px] sm:text-4xl mb-1 mt-16 md:mt-0'>
            {product?.[0]?.fields?.name}
          </div>
          <div className=' font-sans text-[14px]'>
            { product?.[0]?.fields?.subTitle || product?.[0]?.fields?.category?.fields?.name}
          </div>
          <div className={`
            buy-cta fixed duration-700 ${scrollDirection === 'down' ? '-bottom-32' : 'bottom-5'} right-0 w-full shadow-md z-10 transition-all
            bg-white md:static md:shadow-none md:bg-none
          `}>
            <div className='flex flex-row-reverse justify-between pl-4 pr-6 pt-2 pb-3 md:block md:p-0 gap-4'>
              <div className='flex my-auto md:mt-7'>
                <div className={`text-2xl font-inter font-medium text-right block ${!Boolean(product?.[0]?.fields?.price) ? 'whitespace-normal' : 'whitespace-nowrap'}`}>
                  {`${Boolean(product?.[0]?.fields?.price) ?  `$ ${product?.[0]?.fields?.price}.` : 'Price varies by length'}`}
                </div>
                <span className='pb-2 font-inter font-medium'>{Boolean(product?.[0]?.fields?.price) && '00'}</span>
              </div>
              <div className='flex flex-col xxxs:flex-row gap-3 w-full'>
                <div className='bg-black my-auto hover:bg-black/[0.7] transition-all text-white h-[50px] md:h-[40px] rounded-full md:rounded-[20px] space-x-3 leading-[40px] w-full xxxs:w-2/3 md:w-[130px] md:mt-3 flex justify-center items-center'>
                  <div className='w-full flex justify-center text-center'>
                    <a target='_blank' className='w-full' href={product?.[0]?.fields?.buyUrl ?? 'https://www.amazon.com/stores/Sound+Tools/page/CA903747-A545-4448-8D59-488335F86A41?ref_=ast_bln'} rel="noreferrer">Buy Now</a>
                  </div>
                </div>
                <div className='my-auto hover:bg-black/[0.2] transition-all text-black border-black border-solid border h-[50px] md:h-[40px] rounded-full md:rounded-[20px] space-x-3 leading-[40px] w-full xxxs:w-2/3 md:w-[130px] md:mt-3 flex justify-center items-center'>
                  <div className='w-full flex justify-center text-center'>
                    <Link to={'/where-to-buy'}>Dealers</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className='w-full text-wrap mt-7 text-[16px]'>
            {product?.[0]?.fields?.descriptionDetail && documentToReactComponents(product?.[0]?.fields?.descriptionDetail, option)}
          </p>
          {color?.length > 1 && 
          <div className='gender mt-4 flex flex-row gap-4 font-inter text-20 font-semibold items-center'>
            <div className='flex justify-center items-center'>Color:</div>
            {color?.includes('') && <div className='custom-control custom-radio custom-control-inline form-check'>
              <input id='default-color' type='radio' className='custom-control-input radio-custom hidden' name='customRadio' value='' checked={colorValue === ''} onChange={handleColorChange} />
              <div className={`border-2 my-auto text-black h-[50px] md:h-[40px] rounded-full md:rounded-[20px] space-x-3 leading-[40px] px-4 flex justify-center items-center transition-all ${colorValue === '' ? 'border-black' : 'border-gray-400'}`}>
              <label className='custom-control-label cursor-pointer sm:text-16 text-sm ' htmlFor='default-color'>Default</label>
              </div>
            </div>}
            {(color?.includes('') ? color?.slice(1) : color)?.map(c => (
              <div key={c} className='custom-control custom-radio custom-control-inline form-check'>
              <input id={c} type='radio' className='custom-control-input radio-custom hidden' value={c} name='customRadio' checked={colorValue === c} onChange={handleColorChange} />
              <div className={`border-2 my-auto text-black rounded-full w-fit h-fit space-x-3 flex justify-center items-center transition-all ${colorValue === c ? 'border-black' : 'border-gray-400'}`}>
              <label className='custom-control-label cursor-pointer sm:text-16 text-sm w-8 h-8 rounded-full' htmlFor={c} style={{backgroundColor: c}}></label>
              </div>
            </div>
            ))}
          </div>
          }
          {product?.[0]?.fields?.mediaFemale && 
          <div className='gender mt-4 flex flex-row gap-4 font-inter text-20 font-semibold items-center'>
            <div className='flex justify-center items-center'>Gender:</div>
            <div className='custom-control custom-radio custom-control-inline form-check'>
              <input id='male-product' type='radio' className='custom-control-input radio-custom hidden' name='customRadio' value='male' checked={selectedGenderValue === 'male'} onChange={handleRadioChange} />
              <div className={`border-2 border-black my-auto text-black h-[50px] md:h-[40px] rounded-full md:rounded-[20px] space-x-3 leading-[40px] px-4 flex justify-center items-center transition-all ${selectedGenderValue === 'male' ? ' bg-green-700  text-white' : ''}`}>

              <label className='custom-control-label cursor-pointer sm:text-16 text-sm ' htmlFor='male-product'>Male {product?.[0]?.fields?.connectorType || 'XLR'}</label>
              </div>
            </div>
            <div className='custom-control custom-radio custom-control-inline form-check'>
              <input id='female-product' type='radio' className='custom-control-input radio-custom hidden' value='female' name='customRadio' checked={selectedGenderValue === 'female'} onChange={handleRadioChange} />
              <div className={`border-2 border-black my-auto text-black h-[50px] md:h-[40px] rounded-full md:rounded-[20px] space-x-3 leading-[40px] px-4 flex justify-center items-center transition-all ${selectedGenderValue === 'female' ? 'bg-green-700 text-white' : ''}`}>

              <label className='custom-control-label cursor-pointer sm:text-16 text-sm ' htmlFor='female-product'>Female {product?.[0]?.fields?.connectorType || 'XLR'}</label>
              </div>
            </div>
          </div>
          }
          <div className=' mt-6 flex flex-row gap-2 bg-[#F9F9F9] p-2 w-2/3 md:w-3/5 rounded-lg'>
            <img className='' src={WarrantyImage} alt='warranty' />
            <div className='flex flex-col'>
              <div>Lifetime Happy</div>
              <div>Human Warranty</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='px-4 pt-4 mt-12 pb-7 lg:px-16 xl:max-w-container xl:px-0 mx-auto w-full'>
      <InfoTab product={product} />
    </div>
    <div className='support-product flex flex-col m-auto w-1/2'>
      <div className='m-auto mt-7 font-inter text-xl'>Looking for support for this product?</div>
      <div className='m-auto mt-7 mb-12'>
        <div className='bg-black hover:bg-black/[0.7] transition-all text-white h-[40px] rounded-[20px] space-x-3 leading-[40px] w-[130px] flex justify-center items-center'>
          <div>
            <Link to={`/support`}>
              Get Support
            </Link>
          </div>
        </div>
      </div>
    </div>

    <div className='bg-[#F9F9F9] w-full pb-36 pt-16 border-b'>
      <div className='w-full text-center font-inter text-[22px]  sm:text-4xl'>{sectionInfo?.fields?.sectionTitle || 'Featured Products'}</div>
      {sectionInfo?.fields?.shoudShowSectionDescription && <div className='w-full text-center font-sans text-12'>{sectionInfo?.fields?.sectionDescription || ''}</div>}
      <div className='feature-product-container pl-4 flex flex-row gap-5 lg:mx-20 mt-7 relative'>
        <div className='flex flex-row gap-5 overflow-x-auto custom-scrollbar pb-11 xs:pb-0 z-[1]'>
          {featuredProducts.map((product, index) => (
                <div key={`product_${index}`} className='flex product-item bg-white w-[90%] lg:w-2/3 p-4'>
                <CompactProductCard entry={product} isShowPrice={true} />
              </div>
            ))}
        </div>
        <div className='w-full border-b border-solid absolute bottom-0.5 xs:hidden'></div>
      </div>
    </div>
  </div>
  );
};

export default memo(ProductDetailsPage);
