import { memo, useMemo } from 'react';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { separateRichTextNode } from '../../../helpers/common';
import { useInfoTabContext } from '../../../context/infotab-context';

const SpecsAndDetail: React.FC = () => {
    const { product } = useInfoTabContext();

    const specDetails: any = useMemo(() => {
        const seperatedSpecDetail =  separateRichTextNode(product?.[0]?.fields?.specsDetails?.content || []);
        return seperatedSpecDetail.map(element => ({
            nodeType: BLOCKS.DOCUMENT,
            content: element,
            data: {}
        }));
      }, [product]);

    const option = useMemo(() => {
        return {
            renderNode: {
                [BLOCKS.UL_LIST]: (node: any, children: any) => <ul className='mt-[14px]'>{children}</ul>,
                [BLOCKS.LIST_ITEM]: (node: any, children: any) => <li className='text-16 font-inter'>{children}</li>,
                [BLOCKS.HEADING_3]: (node: any, children: any) => <h3 className='font-inter text-[20px]'>{children}</h3>,
                [INLINES.ASSET_HYPERLINK]: (node: any, children: any) => <a className='hover:text-red-800' target='_blank' rel='noreferrer' href={`${node?.data?.target?.fields?.file?.url}`}>{children}</a>,
                [INLINES.HYPERLINK]: (node: any, children: any) => <a target='_blank' rel='noreferrer' className='hover:text-red-800' href={`${node?.data?.uri}`}>{children}</a>,
            }
        }
    }, []);

    return (
        <div className='relative'>
            {/* Content for Specs & Details tab */}
            <div className='grid grid-cols-1 mt-6 md:grid-cols-2 gap-8 mb-10 md:mb-0 md:mt-14 w-full md:w-4/5 mx-auto'>
    
                {
                    specDetails?.map((element: any, index: number) => {
                        return (
                        <div key={`specs_${index}`}>
                            {element && (documentToReactComponents(element, option))}
                        </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default memo(SpecsAndDetail);