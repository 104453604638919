import React, { memo, useEffect, useState } from 'react';
import './info-tab.css'
import ToggleHide from '../../assets/toggle-hide.svg';
import ToggleShow from '../../assets/toggle-show.svg';
import FunctionTab from './tabs/function-tab';
import SpecsAndDetail from './tabs/specs-detail-tab';
import KeyFeatures from './tabs/key-features-tab';
import FAQ from './tabs/faq-tab';
import Warranty from './tabs/warranty-tab';
import { InfoTabContext, useInfoTabContext } from '../../context/infotab-context';

interface IToggleInfoTab {
    tabName: string;
    children: React.ReactNode;
    tabType: string;
}

const listInfoTabKey: string[] = ['specsDetails', 'function', 'faq', 'warranty'];

const InfoTabDesktop: React.FC = memo(() => {
    const [activeTab, setActiveTab] = useState(() => {
        const hash = window?.location?.hash?.slice(1);

        return hash ? hash : 'keyFeatures';
    });
    const { product } = useInfoTabContext();

    useEffect(() => {
        
        if (!product?.[0]?.fields?.keyFeatures && (!window?.location?.hash?.slice(1) || !listInfoTabKey.includes(window?.location?.hash?.slice(1)))) {
            for (let index = 0; index < listInfoTabKey.length; index++) {
                if (product?.[0]?.fields?.[listInfoTabKey[index]]) {
                    setActiveTab(listInfoTabKey[index]);
                    break;
                }
                continue;  
            }
        }
    }, [product]);

    useEffect(() => {
        setActiveTab(prev => window?.location?.hash?.slice(1) || prev);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window?.location?.hash]);


    useEffect(() => {
        const element = document.querySelector('.info-tab');
        let timeoutId: any | undefined = undefined;
        if (element && window?.location?.hash.slice(1)) {
            timeoutId = setTimeout(() => {
                element.scrollIntoView({ behavior: 'smooth' });
            }, 1000);
        }

        return () => clearTimeout(timeoutId);
    }, []);

    return (
        <>
            <div className='info-tab flex flex-row justify-center gap-16 uppercase font-inter relative  h-10 transition-all cursor-pointer'>
                <div className='lg:border-b absolute w-[100vw] bottom-0'></div>
                {product?.[0]?.fields?.specsDetails && <a
                    className={activeTab === 'specsDetails' ? 'active' : 'hover:scale-125 transition-all'}
                    onClick={() => setActiveTab('specsDetails')}
                    href='#specsDetails'
                >
                    Specs & Details
                </a>}
                {product?.[0]?.fields?.keyFeatures && <a
                    className={activeTab === 'keyFeatures' ? 'active' : 'hover:scale-125 transition-all'}
                    onClick={() => setActiveTab('keyFeatures')}
                    href='#keyFeatures'
                >
                    Key Features
                </a>}
                {product?.[0]?.fields?.function && <a
                    className={activeTab === 'function' ? 'active' : 'hover:scale-125 transition-all'}
                    onClick={() => setActiveTab('function')}
                    href='#function'
                >
                    Function
                </a>}
                {product?.[0]?.fields?.faq && <a
                    className={activeTab === 'faq' ? 'active' : 'hover:scale-125 transition-all'}
                    onClick={() => setActiveTab('faq')}
                    href='#faq'
                >
                    F.A.Q
                </a>}
                {product?.[0]?.fields?.warranty && <a
                    className={activeTab === 'warranty' ? 'active' : 'hover:scale-125 transition-all'}
                    onClick={() => setActiveTab('warranty')}
                    href='#warranty'
                >
                    Warranty
                </a>}
            </div>
            <div className='h-auto render-tab transition-all'>
                <div className={activeTab === 'specsDetails' ? 'active' : 'hidden'}>
                    <SpecsAndDetail />
                </div>
                <div className={activeTab === 'keyFeatures' ? 'active' : 'hidden'}>
                    <KeyFeatures />
                </div>
                <div className={activeTab === 'function' ? 'active' : 'hidden'}>
                    <FunctionTab />
                </div>
                <div className={activeTab === 'warranty' ? 'active' : 'hidden'}>
                    <Warranty />
                </div>
                <div className={activeTab === 'faq' ? 'active' : 'hidden'}>
                    <FAQ />
                </div>
            </div>
        </>
    )
});

const ToggleInfoTab: React.FC<IToggleInfoTab> = memo(({ children, tabName, tabType }) => {

    const [isActive, setIsActive] = useState(false);

    const toggleQuestion = () => {
        setIsActive(!isActive);
    };

    useEffect(() => {
        setIsActive(window?.location?.hash?.slice(1) === tabType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const element = document.querySelector('.info-tab');
        let timeoutId: any | undefined = undefined;
        if (element && window?.location?.hash.slice(1)) {
            timeoutId = setTimeout(() => {
                element.scrollIntoView({ behavior: 'smooth' });
            }, 1000);
        }

        return () => clearTimeout(timeoutId);
    }, []);

    return (
        <div className={`detail-box ${isActive ? 'active' : ''} border-t`}>
                    <a
                        className={`py-4 flex justify-between`}
                        onClick={toggleQuestion}
                        href={`#${tabType}`}
                    >
                        {tabName}
                        <div className='inline-block my-auto md:hidden'>
                            <img className={`min-w-[8px] min-h-[8px] ${isActive ? 'hidden' : 'block'}`} src={ToggleShow} alt='m' />
                            <img className={`min-w-[8px] min-h-[8px] ${isActive ? 'block' : 'hidden'}`} src={ToggleHide} alt='m' />
                        </div>
                    </a>
                    <div className={isActive ? 'open-box' : 'close-box'}>
                        {children}
                    </div>
                </div>
    );
});

const InfoTabMobile: React.FC = memo(() => {
    const { product } = useInfoTabContext();

    return (
        <>
            <div className='info-tab border-t md:border-0 flex flex-col md:flex-row justify-center md:gap-16 font-inter relative md:h-10 transition-all'>
                <div className='lg:border-b absolute w-[100vw] bottom-0'></div>
                {product?.[0]?.fields?.specsDetails && <ToggleInfoTab tabName='Specs & Detail' tabType='specsDetails'>
                    <SpecsAndDetail />
                </ToggleInfoTab>}

                {product?.[0]?.fields?.keyFeatures && <ToggleInfoTab tabName='Key Features' tabType='keyFeatures'>
                    <KeyFeatures />
                </ToggleInfoTab>}

                {product?.[0]?.fields?.function && <ToggleInfoTab tabName='Function' tabType='function'>
                    <FunctionTab />
                </ToggleInfoTab>}

                {product?.[0]?.fields?.faq && <ToggleInfoTab tabName='F.A.Q' tabType='faq'>
                    <FAQ />
                </ToggleInfoTab>}

                {product?.[0]?.fields?.warranty && <ToggleInfoTab tabName='Warranty' tabType='warranty'>
                    <Warranty />
                </ToggleInfoTab>}
            </div>
            </>
    );
});

interface IInfoTab {
    product: any;
}

const InfoTab: React.FC<IInfoTab> = ({ product }) => {
    
    const [isMobile, setIsMobile] = useState(false);
    
    
    useEffect(() => {
        setIsMobile(window?.innerWidth <= 768);
        const handleResize = () => {
            setIsMobile(window?.innerWidth <= 768);
        };
        window?.addEventListener('resize', handleResize);
        return () => window?.removeEventListener('resize', handleResize);
    }, []);

    return (
        <InfoTabContext.Provider value={{ product }}>
            <div className='relative'>
                { isMobile ? 
                <InfoTabMobile />
                :
                <InfoTabDesktop />
                }
            </div>
        </InfoTabContext.Provider>
        
    );
};

export default InfoTab;
