import { Author, GhostAPI, Pagination, Params, PostOrPage, PostsOrPages, Tag } from '@tryghost/content-api';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _omit from 'lodash/omit';
import _isEmpty from 'lodash/isEmpty';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import withContentApi from '../hoc/withContentApi';
import SectionTitle from '../components/section-title';
import ThePagination from '../components/pagination';
import LinkRels from '../components/link-rels';
import useWatchPagination from '../custom-hooks/useWatchPagination';
import ArticleCard from '../components/article-card';

interface IProps {
  tags: Tag[];
  api?: GhostAPI;
  allowLoadmore?: boolean;
  articlePerpage?: number;
  sectionTitle?: string;
  sectionPath?: string;
  className?: string;
  posts?: PostOrPage[];
  author?: Author;
}

const FeedPerPage: number = 6;

const SectionFeed: React.FC<IProps> = ({
  tags,
  api,
  allowLoadmore = false,
  articlePerpage = FeedPerPage,
  sectionTitle,
  sectionPath,
  className = 'flex flex-col space-y-3',
  posts,
  author
}) => {
  const [feedArticles, setFeedArticles] = useState<PostOrPage[]>([]);
  const [fetched, setFetched] = useState(false);
  const [searchParams, setSeachParams] = useSearchParams();
  const pageFromParams: string | null = searchParams.get('page');
  const [currentPagination, setCurrentPagination] = useState<Pagination>();
  const currentPage: number = useMemo(() => pageFromParams === null ? 1 : parseInt(pageFromParams), [pageFromParams])
  const setCurrentPage = useCallback((newPage: number) => {
    const category: string | null = searchParams.get('category');
    setSeachParams({ page: newPage.toString(), ...category !== null && { category } });
  }, [setSeachParams, searchParams]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [currentPage]);

  useEffect(() => {
    if (typeof posts !== 'undefined') {
      setFeedArticles(posts);
    }
  }, [tags, posts, author]);

  const requestArticles = useCallback((currentFeeds: PostOrPage[], currentPage?: Pagination) => {
    if (tags.length > 0 || typeof author !== 'undefined') {
      let requestOptions: Params = {
        limit: articlePerpage,
        filter: typeof author !== 'undefined' ? `author:${author.slug}` : `tag:[${_map(tags, (tag: Tag) => tag.slug).join(',')}]`,
        include: ['tags', 'authors'],
        order: 'published_at desc'
      };

      if (typeof currentPage !== 'undefined') {
        const nextPage: number = _get(currentPage, 'next', 0) as number;

        if (nextPage !== null) {
          requestOptions = {
            ...requestOptions,
            page: nextPage
          };
        }
      }

      setFetched(false);
      api?.posts.browse(requestOptions).then((res: PostsOrPages) => {
        setCurrentPagination(res.meta.pagination);
        setFeedArticles(currentFeeds.concat(_map(_omit(res, 'meta')) as PostOrPage[]));
      }).finally(() => setFetched(true));
    }
  }, [api, tags, setFeedArticles, setCurrentPagination, articlePerpage, author]);

  const totalItems: number = useMemo(() => _get(posts, 'length') || currentPagination?.total || 0, [currentPagination, posts]);

  useEffect(() => {
    if (typeof posts === 'undefined') {
      if (currentPage > 0) {
        requestArticles([], { next: currentPage } as Pagination);
      }
    } else {
      const realPage: number = currentPage === 0 ? 0 : currentPage - 1;
      const startItem: number = realPage * articlePerpage;
      const endItems: number = (realPage + 1) * articlePerpage;
      setFeedArticles(posts?.slice(startItem, endItems) as PostOrPage[]);
    }
  }, [currentPage, requestArticles, posts, setFeedArticles, articlePerpage]);

  const dummyArticles: PostOrPage[] = useMemo(() => {
    if (_isEmpty(feedArticles)) {
      return new Array(articlePerpage);
    }

    return feedArticles;
  }, [feedArticles, articlePerpage]);

  const displayOnes = useMemo(() => (typeof posts === 'undefined' ? feedArticles : posts).slice(0, 5), [posts, feedArticles]);

  useWatchPagination({
    page: currentPage === 0 ? 1 : currentPage,
    totalPages: currentPagination?.pages || 0,
    enabled: fetched,
  });

  return (
    <>
      <Helmet>
        <meta name='description' content={`Read about: ${displayOnes.map(item => item.title).join(', ')}`} />
      </Helmet>
      {currentPagination && (
          <LinkRels
            page={currentPagination.page ?? currentPage}
            totalPages={currentPagination?.pages}
          />
        )}
      {
        _isEmpty(sectionTitle) === false && (
          <div className='my-3 sm:mt-0 lg:my-3'>
            <SectionTitle title={sectionTitle || ''} total={totalItems} linkUrl={sectionPath} />
          </div>
        )
      }
      <div className={className}>
        {
          _map(dummyArticles, (article: PostOrPage) => <ArticleCard key={article?.id || Math.random().toString()} article={article} />)
        }
      </div>
      {
        (
          <div className='block mt-16'>
            <ThePagination
              currentPage={currentPage === 0 ? 1 : currentPage}
              totalItems={totalItems}
              itemPerPage={articlePerpage}
              onChangePage={(page: number) => setCurrentPage(page)}
            />
          </div>
        )
      }
    </>
  )
};

export default memo(withContentApi<IProps>(SectionFeed));
