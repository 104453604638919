import { useState, useEffect, useCallback, useRef } from 'react';

const useScrollDirection = (): string | null => {
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down' | null>(null);
  const prevOffset = useRef<number>(0);
  const toggleScrollDirection = useCallback(() => {
     const scrollY = window.scrollY;
     if (scrollY === 0) {
         setScrollDirection(null);
     }
     if (scrollY > prevOffset.current) {
         setScrollDirection('down');
     } else if (scrollY < prevOffset.current) {
         setScrollDirection('up');
     }
     prevOffset.current = scrollY;
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.innerWidth < 480) {
      window.addEventListener('scroll', toggleScrollDirection);
    }

    return () => {
      window.removeEventListener('scroll', toggleScrollDirection);
    };
  }, [toggleScrollDirection]);

  return scrollDirection;
};

export { useScrollDirection };
