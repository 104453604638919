import { Path } from 'react-router-dom';
import _compact from 'lodash/compact';

export const swap = (arr: any[], from: number, to: number): any[] => {
  const processArr = [...arr];
  processArr.splice(from, 1, processArr.splice(to, 1, processArr[from])[0]);
  return processArr;
};

export const bindPageToFunction =
  ({ search, ...rest }: Path) =>
  (page: number | undefined) => {
    const searchParams = new URLSearchParams(search);

    if (page !== undefined) {
      searchParams.set('page', page.toString());
    } else {
      searchParams.delete('page');
    }

    return {
      ...rest,
      search: searchParams.toString(),
    };
  };

export const path2Url = (path: Path) => {
  const searchParams = new URLSearchParams(path.search);

  return _compact([
    _compact([process.env.REACT_APP_BASE_FOLDER, path.pathname]).join('/').replace(/\/+/g, "/"),
    searchParams.toString(),
  ]).join('?');
};

export const separateRichTextNode = (input: any) => {
  const output = [];
  let pickNode = [];
  for (const content of input) {
    if (!content?.content?.[0]?.value?.includes('---')) {
      pickNode.push(content);
    } else {
      if (pickNode.length > 0) {
        output.push(pickNode);
      }
      pickNode = [];
    }
  }

  // Push the remaining elements if any
  if (pickNode.length > 0) {
    output.push(pickNode);
  }

  return output;
}
