import { GhostAPI } from '@tryghost/content-api';
import React, { memo } from 'react';
import withContentApi from '../hoc/withContentApi';
import HomePageHero from '../components/homepage/hero';
import NomePageIntroduction from '../components/homepage/introduction';
import HomePageTestimonials from '../components/homepage/testimonial';
import HomePageFeaturedProduct from '../components/homepage/feature-product';
import HomePageLatestNews from '../components/homepage/latest-news';
import HomePageDesktopCategory from '../components/homepage/desktop-product-category';
import HeaderMeta from '../components/header-meta';
import ProductCategories from '../components/product-categories';
import './homepage.css';

interface IProps {
  api?: GhostAPI;
};

const HomePage: React.FC<IProps> = ({ api }) => {
  return (
    <>
      <HeaderMeta />
      <div className=''>
        <HomePageHero />
        <div className='px-0 lg:px-16 xl:max-w-container xl:px-0 mx-auto w-full'>
          <NomePageIntroduction />
          <div className='lg:hidden'>
            <ProductCategories />
          </div>
          <HomePageDesktopCategory />
          <HomePageFeaturedProduct />
          <HomePageLatestNews />
          <HomePageTestimonials />
        </div>
      </div>
    </>
  )
};

export default memo(withContentApi<IProps>(HomePage));
