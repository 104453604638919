import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import _sortBy from 'lodash/sortBy';
import { useAppContext } from '../context/app-context';
import { filterItems } from '../helpers/contentful-helper';
import { Link, useSearchParams } from 'react-router-dom';

interface IProps {
  hasCategoryDescription?: boolean;
  handleProductCategory?: any;
  hasAllProductInList?: boolean;
}

const ProductCategories: React.FC<IProps> = ({ hasCategoryDescription, hasAllProductInList }) => {
  const [searchParams] = useSearchParams();
  const { rawContentFul } = useAppContext();
  const categories = useMemo(() => {
    return _sortBy(filterItems(rawContentFul, 'productCategory'), 'fields.order');
  }, [rawContentFul]);
  const [highlightedCategory, setHighlightedCategory] = useState<number>(() => {
    const index = categories?.findIndex(category => category?.fields?.name?.toString()?.toLowerCase() === searchParams.get('category'));

    return index < 0 ? 0 : index;
  });

  const handleCategoryClick = useCallback((category) => {
    setHighlightedCategory(category?.fields?.order);
  }, []);

  useEffect(() => {
    const categoryIndex = searchParams.get('category') ? categories?.findIndex(category => category?.fields?.name?.toString()?.toLowerCase() === searchParams.get('category')) : 0;
    setHighlightedCategory(categoryIndex);
  }, [categories, searchParams])

  return (
    <div>
      <div className='product-category-wrapper pt-5 page-width mb-3 w-full whitespace-nowrap md:whitespace-normal overflow-x-auto'>
        <ul className='list-none p-0 space-x-4 my-2 text-center space-y-2'>
        {
          categories?.slice(hasAllProductInList ? 0 : 1).map(item => (
            <li className='inline-block w-[100px] hover:scale-125 transition-all' key={item.sys.id} onClick={() => handleCategoryClick(item)}>
              <Link to={`/products?category=${encodeURIComponent(item?.fields?.name?.toString().toLowerCase() || '')}`}>
                <div className='flex flex-col w-full items-center'>
                  <div className={`w-[96px] h-[96px] rounded-full pt-0.5 border-2 border-solid transition-all duration-500 ${item?.fields?.order === highlightedCategory ? 'border-black' : 'border-white'}`}>
                  <div style={{ backgroundColor: `${item.fields.colorHex || '#fff'}`}} className='w-[88px] h-[88px] rounded-full m-auto'>
                    {/* @ts-ignore */}
                    <img className='mx-auto h-full w-4/5 object-contain' src={item.fields.photo?.fields?.file?.url} alt={item?.fields?.name?.toString() || ''} />
                  </div>
                  </div>
                  
                  <p className={`capitalize w-full text-wrap mt-4 text-[14px] text-center font-sans ${item?.fields?.order === highlightedCategory && 'font-bold'}`}>
                    {item.fields.name?.toString().toLowerCase()}
                  </p>
                </div>
              </Link>
              
            </li>
          ))
        }
        </ul>
      </div>

      {hasCategoryDescription && <div className='category-description m-auto w-full lg:w-3/5'>
        <div className='w-full text-center font-inter text-[22px] sm:text-4xl mb-5'>{categories[highlightedCategory]?.fields?.name}</div>
        <p className='w-full text-wrap mt-4 text-[16px] text-center px-4'>{categories[highlightedCategory]?.fields?.description}</p>
      </div>}
    </div>
  )
};

export default memo(ProductCategories);
