import React, { memo, useMemo } from 'react';

import TroubleshootingLogo from '../../assets/banner1.png';
import ToolLogo from '../../assets/BANNER4.png';
import { Link } from 'react-router-dom';
import { useAppContext } from '../../context/app-context';
import { filterItems } from '../../helpers/contentful-helper';

const IntroductionHomePage: React.FC = () => {
  const { rawContentFul } = useAppContext();
  const firstPanel = useMemo(() => filterItems(rawContentFul, 'sectionInfo')?.filter(data => (
    data?.fields?.type === 'firstIntroductionPanel'
  ))?.[0], [rawContentFul]);
  const secondPanel = useMemo(() => filterItems(rawContentFul, 'sectionInfo')?.filter(data => (
    data?.fields?.type === 'secondIntroductionPanel'
  ))?.[0], [rawContentFul]);

  const [firstButtonBorder, firstButtonColor, secondButtonColor, secondButtonBorder] = useMemo(() => {
    const firstBtnBorder =  firstPanel?.fields?.firstButtonBorder;
    const firstBtnColor = firstPanel?.fields?.firstButtonColor;
    const secondBtnBorder =  secondPanel?.fields?.secondButtonBorder;
    const secondBtnColor = secondPanel?.fields?.secondButtonColor;

    return [
      firstBtnBorder ? `border ${firstBtnBorder} border-solid` : '',
      firstBtnColor ? `bg-[${firstBtnColor}]` : 'bg-white',
      secondBtnBorder ? `border ${secondBtnBorder} border-solid` : '',
      secondBtnColor ? `bg-[${secondBtnColor}]` : 'bg-black',
    ];
  }, [firstPanel, secondPanel]);

  return (
    <div className='flex flex-col space-y-2 mt-2 lg:mt-7 lg:flex-row lg:space-y-0 lg:space-x-6'>
      <div className='relative lg:flex-1'>
        {/* @ts-ignore */}
        <img className='w-full min-h-[420px] object-cover' src={`${firstPanel?.fields?.panelImage?.fields?.file?.url || TroubleshootingLogo}`} alt='Troubleshooting' />
        <div className='w-full absolute top-0 text-center flex flex-col mt-6 items-center'>
          <div className='text-[22px] font-inter xs:w-1/2 lg:w-3/4 lg:text-[36px]'>{`${ firstPanel?.fields?.sectionTitle || 'Troubleshooting is stressfull enough'}`}</div>
          <div className='text-[15px] mt-3 lg:mt-6 px-8 lg:px-10 lg:text-[16px] backdrop-blur-sm'>{`${
            firstPanel?.fields?.sectionDescription || 'Don’t get caught without the right (sound) tool. There are plenty of things that can go wrong in a complex set up and your time is important.'
            }`}</div>
          <div className='flex items-center justify-center mt-4 lg:mt-7 flex-col xxxs:flex-row gap-3'>
          <Link to={`${firstPanel?.fields?.firstUrl || '/products'}`}>
            <div
              className={`
                hover:bg-black/[0.2] transition-all flex items-center justify-center space-x-5 rounded-[20px] text-black h-[40px] leading-[40px] px-5 w-[170px] bg-white backdrop-blur-3xl ${firstButtonBorder} ${firstButtonColor}
              `}>
                <div className='whitespace-nowrap'>
                  { firstPanel?.fields?.firstButtonText || 'Learn more'}
                </div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" height={15}><path fill='' d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
            </div>
            </Link>
            <Link to={`${firstPanel?.fields?.secondUrl || '/where-to-buy'}`}>
              <div
                className={`border-black flex bg-black hover:bg-black/[0.6] transition-all justify-center items-center space-x-5 border rounded-[20px] text-white h-[40px] leading-[40px] px-5 w-[170px] ${secondButtonBorder} ${secondButtonColor}`}>
                <div className='whitespace-nowrap'>
                  { firstPanel?.fields?.secondButtonText || 'Find a dealer'}
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" height={15}><path fill='#ffffff' d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className='relative text-white lg:flex-1'>
        {/* @ts-ignore */}
        <img className='w-full min-h-[420px] object-cover' src={`${secondPanel?.fields?.panelImage?.fields?.file?.url || ToolLogo}`} alt='Tool' />
        <div className='w-full absolute top-0 text-center flex flex-col mt-6 items-center'>
          <div className='text-[22px] font-inter lg:w-1/2 lg:text-[36px]'>{`${ secondPanel?.fields?.sectionTitle || 'Save time & be confident'}`}</div>
          <div className='text-[15px] mt-3 lg:mt-6 px-8 lg:px-13 lg:text-[16px]'>
            {`${
              secondPanel?.fields?.sectionDescription || 'Reduce your set up and tear down time with products you can rely on for life. All products are backed by a lifetime warranty.'
            }`}
            </div>
          <div className='flex items-center justify-center mt-4 lg:mt-7 flex-col xxxs:flex-row gap-3'>
          <Link to={`${secondPanel?.fields?.firstUrl || '/support'}`}>
            <div
              className={`border-white hover:bg-white/[0.2] transition-all flex items-center justify-center space-x-5 border rounded-[20px] text-white h-[40px] leading-[40px] px-5 backdrop-blur-3xl ${secondButtonBorder} ${secondButtonColor}`}>
                <div className='whitespace-nowrap'>{ secondPanel?.fields?.firstButtonText || 'Lifetime warranty'}</div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" height={15}><path fill='white' d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
            </div>
            </Link>
            <Link to={`${secondPanel?.fields?.secondUrl || '/where-to-buy'}`}>
              <div
                className={`border-white flex bg-white hover:bg-white/[0.6] transition-all justify-center items-center space-x-5 border rounded-[20px] text-black h-[40px] leading-[40px] px-5 w-[170px] ${firstButtonBorder} ${firstButtonColor}`}>
                  <div className='whitespace-nowrap'>{ secondPanel?.fields?.secondButtonText || 'Find a dealer'}</div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" height={15}><path fill='' d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
};

export default memo(IntroductionHomePage);