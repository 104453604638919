import React, { memo, useMemo } from 'react';
import { useAppContext } from '../../context/app-context';
import { filterItems } from '../../helpers/contentful-helper';
import CompactProductCard from '../compact-product-card';

const HomePageFeaturedProduct: React.FC = () => {
  const { rawContentFul } = useAppContext();
  const featuredProducts = useMemo(() => {
    return filterItems(rawContentFul, 'product').filter(item => item.fields?.isFeatured === true).slice(0, 4);
  }, [rawContentFul]);
  const sectionInfo = useMemo(() => filterItems(rawContentFul, 'sectionInfo')?.filter(data => (
    data?.fields?.type === 'featuredProductSection'
  ))?.[0], [rawContentFul]);

  return (
    <div className='flex flex-col mt-9 feature-producs'>
      <div className='w-full text-center font-inter text-[22px]  sm:text-4xl'>{sectionInfo?.fields?.sectionTitle || 'Featured Products'}</div>
      {sectionInfo?.fields?.shoudShowSectionDescription && <div className='w-full text-center font-sans text-12'>{sectionInfo?.fields?.sectionDescription || ''}</div>}
      <div className='flex flex-col lg:flex-row mt-7 md:mt-9 space-y-3 lg:space-y-0 lg:space-x-6'>
        <div className='flex feature-product-0'>
          <CompactProductCard entry={featuredProducts[0]} />
        </div>
        <div className='flex flex-col space-y-3'>
          <div className='flex space-x-3 items-stretch'>
            <div className='flex-1 feature-product-1'>
              <CompactProductCard smallCard entry={featuredProducts[1]} />
            </div>
            <div className='flex-1 feature-product-2'>
              <CompactProductCard smallCard entry={featuredProducts[2]} />
            </div>
          </div>
          <div className='feature-product-3'>
            <CompactProductCard entry={featuredProducts[3]} />
          </div>
        </div>
      </div>
    </div>
  )
};

export default memo(HomePageFeaturedProduct);
