import { memo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { bindPageToFunction, path2Url } from '../helpers/common';

const LinkRels: React.FC<{
  page: number;
  totalPages: number;
}> = ({ page, totalPages }) => {
  const location = useLocation();

  const createAbsoluteUrl = useCallback(
    (p: number | undefined) => {
      const relativeSegment = path2Url(bindPageToFunction(location)(p));
      
      return [window.location.origin, relativeSegment].join('/').replace(/\/+/g, "/")
    },
    [location]
  );

  return (
    <Helmet>
      {page >= 1 && page < totalPages && (
        <link
          key="articalNextPage"
          rel="next"
          href={createAbsoluteUrl(page + 1)}
        />
      )}
      {page >= 1 && (
        <link
          rel="canonical"
          key="canonical"
          href={createAbsoluteUrl(page === 1 ? undefined : page)}
        />
      )}
      {page > 1 && (
        <link
          key="articalPrevPage"
          rel="prev"
          href={createAbsoluteUrl(page === 2 ? undefined : page - 1)}
        />
      )}
    </Helmet>
  );
};

export default memo(LinkRels);
