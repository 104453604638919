import React from 'react';
import _get from 'lodash/get';
import GhostContentAPI, { GhostAPI } from '@tryghost/content-api';

function withContentApi<IProps>(Component: React.FC<IProps>) {
  return (
    props: IProps
  ) => {
    const api: GhostAPI = new GhostContentAPI({
      url: _get(process.env, 'REACT_APP_GHOST_API_URL', ''),
      key: _get(process.env, 'REACT_APP_GHOST_API_KEY', ''),
      version: 'v3'
    });
  
    return <Component { ...props } api={api} />
  };
}

export default withContentApi;
