import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAppContext } from '../../context/app-context';
import ArticleCard from '../article-card';
import PrevArrow from './../../assets/prev-arrow.svg';
import NextArrow from './../../assets/next-arrow.svg';
import { PostOrPage } from '@tryghost/content-api';
import { filterItems } from '../../helpers/contentful-helper';


const HomePageLatestNews: React.FC = () => {
  const { allPosts, rawContentFul } = useAppContext();
  const [isDesktop, setIsDesktop] = useState<boolean>(false);
  const [isClearAutoSwipe, setIsClearAutoSwipe] = useState<boolean>(false);
  const listArticleRef = useRef<HTMLUListElement>(null);
  const length = useMemo(() => allPosts?.length, [allPosts]);
  const sectionInfo = useMemo(() => filterItems(rawContentFul, 'sectionInfo')?.filter(data => (
    data?.fields?.type === 'latestNewsSection'
  ))?.[0], [rawContentFul]);
  // @ts-ignore
  const clonePosts: PostOrPage[] = useMemo(() => {
    let clone = allPosts?.map(post => ({ ...post }));
    if (isDesktop && length > 3) {
      const clone12 = clone?.slice(0,3);
      const clone34 = clone?.slice(-3);
      clone.push(...clone12);
      clone.unshift(...clone34);
    }
    return clone;
  }, [allPosts, isDesktop, length]);

  const temp = useRef<number>(3);

  const debounce = (func: any, delay: number) => {
    let timeoutId: any;
    return (...args: any[]) => {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
  };

  const handleClick = useCallback((type: string) => {
    let cycle = false,
          delta = (type === 'prev') ? -1 : 1;
    temp.current = temp.current + delta;
    if (listArticleRef.current) {
      listArticleRef.current.style.transition = `all .2s ease-in-out`;
      listArticleRef.current.style.transform = `translateX(${temp?.current * 438 * -1}px)`;
    }

    cycle = !!(temp?.current === 0 || (temp?.current > length + 2 && length > 0));
    
    if (cycle) {
      setTimeout(() => {
        if (listArticleRef.current) {
          temp.current = (temp.current === 0) ? length : 3;
          listArticleRef.current.style.transition = `none`;
          listArticleRef.current.style.transform = `translateX(${temp?.current * 438 * -1}px)`;
        }
      }, 200);
    }
  }, [length]);

  const debounceClick = debounce(handleClick, 200);

  useEffect(() => {
    if (listArticleRef.current && typeof window !== 'undefined') {
      if (isDesktop && length > 3) {
        listArticleRef.current.style.transform = `translateX(${temp?.current * 438 * -1}px)`;
      }
    }
  }, [isDesktop, length]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsDesktop(window.innerWidth > 1280);
    }
  }, []);

  useEffect(() => {
    if (length > 3 && isDesktop) {
      const interval = setInterval(() => {
        if (listArticleRef.current && typeof window !== 'undefined') {
          debounceClick('next')
        }
      }, 3000);
  
      if (isClearAutoSwipe) {
        clearInterval(interval);
      }
  
      return () => clearInterval(interval);
    }
  }, [debounceClick, isClearAutoSwipe, isDesktop, length]);


  return (
    <div className='py-5 mt-10'>
      <div className='relative product-category-wrapper page-width mb-3 w-full '>
        <div className=' w-full font-inter text-[22px] sm:text-4xl text-center'>{sectionInfo?.fields?.sectionTitle || 'The Latest News'}</div>
        {sectionInfo?.fields?.shoudShowSectionDescription && <div className='w-full text-center font-sans text-12'>{sectionInfo?.fields?.sectionDescription || ''}</div>}
        <img src={PrevArrow} alt='prev-arrow' className={`absolute left-[-28px] top-[40%] hidden xl:block ${length <= 3 ? 'xl:hidden': ''}
         cursor-pointer hover:scale-150`} onClick={() => {
            setIsClearAutoSwipe(true);
            debounceClick('prev');
          }} />
          <img src={NextArrow} alt='next-arrow' className={`absolute right-[-28px] hidden xl:block ${length <= 3 ? 'xl:hidden': ''}
           top-[40%] cursor-pointer hover:scale-150 `} onClick={() => debounceClick('next')} />
        <div className='list-testimonial relative list-none p-0 space-x-3 my-2 block w-full xl:max-w-container
        mt-9 whitespace-nowrap md:whitespace-normal overflow-x-auto xl:overflow-hidden custom-scrollbar z-[1]'>
          <ul className={`flex flex-row w-fit`} ref={listArticleRef}>
            {
            clonePosts?.map((item: PostOrPage, index: number) => (
              <li className='inline-block w-[328px] xl:w-[414px] mr-4 sm:mr-[30px] xl:mr-6 min-h-[435px] bg-white pb-10' key={`${item?.id}_${index}`}>
                {/* <div>{index-1}</div> */}
                <div className='flex flex-col w-full h-full'>
                  <ArticleCard article={item} />
                </div>
              </li>
            ))
          }
        </ul>
        </div>
        <div className='w-full border-b border-solid absolute bottom-[30px] xs:hidden'></div>
      </div>
    </div>
  )
};

export default memo(HomePageLatestNews);
