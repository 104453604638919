import { GhostAPI, PostOrPage, PostsOrPages } from '@tryghost/content-api';
import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import * as contentful from 'contentful';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import _map from 'lodash/map';
import { subscribe } from './api-helpers/subscriber';
import Footer from './components/footer';
import Header from './components/header';
import { AppContext } from './context/app-context';
import withContentApi from './hoc/withContentApi';
import HomePage from './pages/homepage';
import SectionFrontV2 from './pages/section-front-v2';
import { validateEmail } from './helpers/date-time';
import HeaderMeta from './components/header-meta';
import { IContentFulRaw } from './model/contentful-model';
import ProductsPage from './pages/products-page';
import ProductDetailsPage from './pages/product-details-page';
import SupportPage from './pages/support-page';
import { LoadScript } from '@react-google-maps/api';

const ArticleDetailsPage = lazy(() => import('./pages/article-details-page'));
const AboutUsPage = lazy(() => import('./pages/about-us-page'));
const NotFoundPage = lazy(() => import('./pages/404-page'));
const WhereToBuyPage = lazy(() => import('./pages/where-to-buy-page'));

interface IProps {
  api?: GhostAPI;
}

const client = contentful.createClient({
  // This is the space ID. A space is like a project folder in Contentful terms
  space: process.env.REACT_APP_CONTENT_SPACE || '',
  // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
  accessToken: process.env.REACT_APP_CONTENT_API || '',
});

const sessionKey = `${new Date().toLocaleDateString()}_cache_data`;

const App: React.FC<IProps> = ({ api }) => {
  const [rawContentFul, setRawContentFul] = useState<IContentFulRaw>();
  const [subscribed, setSubscribed] = useState(window.sessionStorage.getItem('sub_email') ? true : false);
  const [subscribeText, setSubscribeText] = useState('Thank you for signing up. More SoundTools news is headed your way!');
  const [loading, setLoading] = useState(false);
  const [allPosts, setAllPosts] = useState<PostOrPage[]>([]);
  const [featuredHumans, setFeaturedHumans] = useState<PostOrPage[]>([]);
  const location = useLocation();

  const onSubscribe = useCallback((email: string, fromForm: string): void => {
    if (validateEmail(email) === false) {
      return window.alert('Please input a valid email address');
    }

    setLoading(true);
    subscribe(email, fromForm, (alreadySubscribed: boolean) => {
      setSubscribed(true);
      setLoading(false);

      if (alreadySubscribed === true) {
        setSubscribeText('You are already subscribed to our mailing list!');
      } else {
        window.sessionStorage.setItem('sub_email', email);
      }
    }, () => {
      setLoading(false);
      window.alert('Please input a valid email address');
    });
  }, [setSubscribed, setLoading, setSubscribeText]);

  useEffect(() => {
    if (_isEmpty(allPosts) === true) {
      api?.posts.browse({ limit: 'all', include: ['tags', 'authors'] }).then((res: PostsOrPages) => {
        const allArticles = _map(_map(_omit(res, 'meta')) as PostOrPage[], (post: PostOrPage) => ({ ...post, html: post.html?.replace(/(<([^>]+)>)/ig, '') }));
        const allPosts = allArticles.filter(item => !item.tags?.some(tag => tag.name === 'human'));
        const allHumans = allArticles.filter(item => item.tags?.some(tag => tag.name === 'human'));
        setFeaturedHumans(allHumans);
        setAllPosts(allPosts);
      });
    }
  }, [allPosts, api]);

  useEffect(() => {
    setLoading(_isEmpty(rawContentFul));
  }, [rawContentFul]);

  useEffect(() => {
    if (sessionStorage) {
      const data = sessionStorage.getItem(sessionKey);

      if (data) {
        console.log('====using cache');
        setRawContentFul(JSON.parse(data));
      } else {
        console.log('====request online data');
        client.getEntries().then(resData => {
          // @ts-ignore
          setRawContentFul(resData);
          sessionStorage.setItem(sessionKey, JSON.stringify(resData));
        });
      }
    }
  }, []);

  return (
    <>
      <HeaderMeta />
      <AppContext.Provider value={{
        hasSubscribed: subscribed,
        onSubscribe,
        allPosts,
        subscribeText,
        rawContentFul,
        featuredHumans
      }}>
        <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY || ''}>
        <div className={`flex flex-col w-full min-h-screen ${location.pathname === '/glossary' ? '' : 'overflow-x-hidden'}`}>
          <div className='flex flex-col flex-grow w-full'>
            <div className='mr-0 ml-0 w-full relative'>
              <Header />
            </div>
            <div className='lg:px-0 flex-grow'>
              <Routes>
                <Route path='/products/:productId' element={
                  <Suspense fallback={<div className='px-4 lg:px-76px box-content py-6 w-full bg-[#F9F9F9]'>Loading...</div>}>
                    <ProductDetailsPage />
                  </Suspense>
                } />
                <Route path='/articles/:slug' element={
                  <Suspense fallback={<div className='px-4 lg:px-76px box-content py-6 w-full bg-[#F9F9F9]'>Loading...</div>}>
                    <ArticleDetailsPage />
                  </Suspense>
                } />
                <Route path='/articles/category/:categoryId' element={<SectionFrontV2 />} />
                <Route path='/guides/category/:categoryId' element={<SectionFrontV2 />} />
                <Route path='/articles/category' element={<SectionFrontV2 />} />
                <Route path='/guides/category' element={<SectionFrontV2 />} />
                <Route path='/products' element={
                <Suspense fallback={<div>Loading...</div>}>
                    <ProductsPage />
                  </Suspense>} />
                <Route path='/articles' element={<SectionFrontV2 />} />
                <Route path='/about-us' element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <AboutUsPage />
                  </Suspense>
                } />
                <Route path='/where-to-buy' element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <WhereToBuyPage />
                  </Suspense>
                } />
                <Route path='/support' element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <SupportPage />
                  </Suspense>
                } />
                <Route path='*' element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <NotFoundPage />
                  </Suspense>
                } />
                <Route path='/' element={<HomePage />} />
              </Routes>
            </div>
          </div>
          <div className='w-full bg-[#F9F9F9]'>
            <div className='flex flex-col flex-grow px-0 lg:px-16 xl:px-0 xl:max-w-container mx-auto w-full'>
              <Footer />
            </div>
          </div>
        </div>
        </LoadScript>
        {
          loading && (
            <div className='w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50'>
              <span className='text-green-500 opacity-75 top-1/2 my-0  block relative w-full h-0 '>
                <svg fill='none' className='h-100 w-100 mx-auto animate-spin' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'>
                  <path clipRule='evenodd'
                    d='M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z'
                    fill='currentColor' fillRule='evenodd' />
                </svg>
              </span>
            </div>
          )
        }
      </AppContext.Provider>
    </>

  )
}

export default withContentApi<IProps>(App);
