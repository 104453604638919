import React, { memo, useMemo } from 'react';
import { useAppContext } from '../../context/app-context';
import { filterItems } from '../../helpers/contentful-helper';
import { Link } from 'react-router-dom';

const HomePageHero: React.FC = () => {
  const { rawContentFul } = useAppContext();
  const heroPhoto = useMemo(() => {
    const items = filterItems(rawContentFul, 'homePageHero');

    return items[0];
  }, [rawContentFul]);

  return (
    // @ts-ignore
    <div style={{ backgroundImage: `url(${heroPhoto?.fields?.photo?.fields?.file.url})`, backgroundSize: 'cover', backgroundPosition: 'center'}}
      className='w-full h-[260px] lg:h-[711px] p-5 lg:-mt-[128px] lg:w-full lg:max-w-none'>
        <div className='max-w-md-container xl:max-w-container mx-auto w-full'>
        <div className='text-white font-inter font-bold text-[28px] capitalize pr-4 xs:pr-20 leading-7 mt-5 lg:mt-80 lg:uppercase lg:text-[53px] lg:max-w-2xl lg:leading-[50px]'>
        {heroPhoto?.fields?.headline}
      </div>
      <div className='text-white font-inter text-[16px] w-4/5 xs:w-2/3 mt-[6px] lg:mt-4 lg:text-[20px]'>
        {heroPhoto?.fields?.subHeadline}
      </div>
      <Link to={'/where-to-buy'}>
      <div className='border-white hover:bg-white/[0.3] transition-all flex items-center justify-center space-x-5 border rounded-[20px] text-white h-[40px] leading-[40px] mt-2 xs:mt-5 lg:mt-7 px-5 w-[170px]'>
        <div>
          {heroPhoto?.fields?.ctaText}
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" height={15}><path fill='#ffffff' d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
      </div>
      </Link>
        </div>
      
    </div>
  )
};

export default memo(HomePageHero);