import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function useWatchPagination({
  page,
  totalPages,
  enabled,
}: {
  page: number;
  totalPages: number;
  enabled: boolean;
}) {
  const navigate = useNavigate();

  useEffect(() => {
    if (enabled && page > totalPages) {
      navigate('/not-found');
    }
  }, [enabled, navigate, totalPages, page]);
}
