import { PostOrPage } from '@tryghost/content-api';
import React, { memo } from 'react';
import { formatDate } from '../helpers/date-time';
import { Link } from 'react-router-dom';

interface IProps {
  article: PostOrPage
  isCompact?: boolean
}

const ArticleCard: React.FC<IProps> = ({ article, isCompact = false }) => {
  return (
    <div className='flex flex-col w-full'>
      <div className={`w-full overflow-hidden scale-100 aspect-video-card hover:scale-105 transition object-center`}>
      <Link to={`/articles/${article?.slug}`}>
        {/* @ts-ignore */}
        <img className='w-full object-cover h-full' src={article?.feature_image || ''} alt={article?.feature_image_alt || ''} />
      </Link>
      </div>
      <Link to={`/articles/${article?.slug}`} className='w-full text-wrap text-[18px] font-inter capitalize mt-3 font-b'>
        {article?.title?.toLowerCase()}
      </Link>
      <p className='text-[16px] text-[#7B7B7B] mt-2'>
        {formatDate(new Date(article?.published_at || ''))}
      </p>
      {
        isCompact === false && (
          <>
            <p className='text-[16px] text-wrap mt-2 mb-3 line-clamp-3'>
              {article?.excerpt}
            </p>
            <Link to={`/articles/${article?.slug}`} className='font-inter cursor-pointer font-bold text-[14px]'>Read More &rarr;</Link>
          </>
        )
      }
    </div>
  )
};

export default memo(ArticleCard);
