import { createContext, useContext } from 'react';

export interface IInfoTabStore {
  product: any;
}

export const InfoTabContext = createContext<IInfoTabStore>({
  product: [],
});

export const useInfoTabContext = () => useContext(InfoTabContext);
